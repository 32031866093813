import React from 'react';
import './App.css';
import OverlayImages from './OverlayImages';

function App() {
  return (
    <div className="App">
      <nav className="navbar">
        <h1>Intelligent Transportation Systems for Curbside Management</h1>
      </nav>
      <header className="App-header">
        <p>Curbside management involves the allocation and regulation of curb space to optimize its use for activities such as parking, loading, and public transportation. This comprehensive approach balances the diverse needs of roadway users, including pedestrians, cyclists, public transit, and commercial vehicles, to improve mobility, safety, accessibility, and efficiency in urban areas. Recent advancements have focused on leveraging intelligent transportation system (ITS) technologies to dynamically manage and share information on available curb space. This visualization was developed using publicly available evaluation and research studies from the Benefits, Costs, and Lessons Learned Database and shows selected technologies that facilitate curbside management.</p>
        <i><strong>Tips for Using the Visualization:</strong> Hover your mouse over a technology and click for more information. Hover over the dotted lines for details on interrelated technologies.</i>
      </header>
      <div className='overlay-images'>
        <OverlayImages />
      </div>
    </div>
  );
}

export default App;
