// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App i{
  color: #2a517f;
}

.navbar {
  background-color: #282c34;
  padding: 1rem;
  color: white;
}

.App-header {
  margin: 2rem 10%;
  text-align: left;
}

.overlay-images {
  margin: 0 auto;
  position: relative;
  width: 60%;
  overflow: hidden;
}
/*
.overlay-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}
*/

@media (max-width:1000px) {
  .overlay-images {
    width: 80%;
  }
}

@media (max-width:700px) {
  .App-header {
    margin: 2rem 2%;
  }
  .overlay-images {
    width: 98%;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;AACA;;;;;;;CAOC;;AAED;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App i{\n  color: #2a517f;\n}\n\n.navbar {\n  background-color: #282c34;\n  padding: 1rem;\n  color: white;\n}\n\n.App-header {\n  margin: 2rem 10%;\n  text-align: left;\n}\n\n.overlay-images {\n  margin: 0 auto;\n  position: relative;\n  width: 60%;\n  overflow: hidden;\n}\n/*\n.overlay-container {\n  position: relative;\n  width: 100%;\n  height: 80vh;\n  overflow: hidden;\n}\n*/\n\n@media (max-width:1000px) {\n  .overlay-images {\n    width: 80%;\n  }\n}\n\n@media (max-width:700px) {\n  .App-header {\n    margin: 2rem 2%;\n  }\n  .overlay-images {\n    width: 98%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
