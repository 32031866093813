import React, { useEffect, useRef } from 'react';
import './Popup.css';

const parseText = (input) => {
  const parts = input.split('|');
  return parts.map((part, index) => {
    if (part.includes('~')) {
      const [text, link] = part.split('~').map(str => str.trim());
      return (
        <span key={index}>
          <a href={link} target="_blank" rel="noopener noreferrer">{text}</a>
        </span>
      );
    } else {
      return (
        <span key={index}>
          {part}
        </span>
      );
    }
  });
};

const renderContent = (category, text) => {
  switch (category) {
    case 'Link':
      return <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>;
    case 'Taxonomy':
      return parseText(text);
    default:
      return text;
  }
};

const Popup = ({ x, y, description, transform, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    // Scroll to the top whenever description changes
    if (popupRef.current) {
      popupRef.current.scrollTop = 0;
    }
  }, [description]);

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Stop the click event from propagating to the parent
  };

  return (
    <div
      className="popup"
      style={{ top: y, left: x, transform }}
      onMouseDown={handlePopupClick}
      ref={popupRef} // Attach ref to the popup div
    >
      <span className="popup-close" onClick={onClose}>&times;</span>
      {description && Object.entries(description).map(([category, text]) => {
        // Skip rendering if text is empty or null
        if (!text || text.trim() === '') {
          return null;
        }

        return (
          <div key={category}>
            <strong>{category.replace(/_/g, ' ')}:</strong>
            <div>
              {renderContent(category, text)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Popup;
