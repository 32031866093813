// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
  position: fixed;
  z-index: 100;
  width: 30vw;
  max-height: 30vh; /* Set fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0 2rem 2rem 2rem;
  font-size: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.95);
  text-align: left;
}


.popup * , .popup *::before , .popup *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.popup h2 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.popup h3 {
  font-size: 1.4rem;
}

.popup section {
  margin-bottom: 0.5rem;
}

.popup a {
  text-decoration: underline;
}

.popup a:link {
  color: var(--color-strong-blue);
}

.popup a:hover,
.popup a:active {
  color: var(--color-strong-yellow);
}

.popup a:visited {
  color: var(--color-strong-blue);
}

.popup-close {
  color: var(--color-light-grey);
  cursor: pointer;
  font-size: 2.4rem;
  line-height: 1;
  position: sticky;
  left:30vw;
  /* right: 0.8rem; */
  top: 0.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/Popup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,gBAAgB,EAAE,qBAAqB;EACvC,gBAAgB,EAAE,8BAA8B;EAChD,yBAAyB;EACzB,eAAe;EACf,2CAA2C;EAC3C,2CAA2C;EAC3C,gBAAgB;AAClB;;;AAGA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;;EAEE,iCAAiC;AACnC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,SAAS;EACT,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".popup {\n  position: fixed;\n  z-index: 100;\n  width: 30vw;\n  max-height: 30vh; /* Set fixed height */\n  overflow-y: auto; /* Enable vertical scrolling */\n  padding: 0 2rem 2rem 2rem;\n  font-size: 1rem;\n  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);\n  background-color: rgba(255, 255, 255, 0.95);\n  text-align: left;\n}\n\n\n.popup * , .popup *::before , .popup *::after {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.popup h2 {\n  font-size: 1.7rem;\n  margin-bottom: 1rem;\n}\n\n.popup h3 {\n  font-size: 1.4rem;\n}\n\n.popup section {\n  margin-bottom: 0.5rem;\n}\n\n.popup a {\n  text-decoration: underline;\n}\n\n.popup a:link {\n  color: var(--color-strong-blue);\n}\n\n.popup a:hover,\n.popup a:active {\n  color: var(--color-strong-yellow);\n}\n\n.popup a:visited {\n  color: var(--color-strong-blue);\n}\n\n.popup-close {\n  color: var(--color-light-grey);\n  cursor: pointer;\n  font-size: 2.4rem;\n  line-height: 1;\n  position: sticky;\n  left:30vw;\n  /* right: 0.8rem; */\n  top: 0.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
