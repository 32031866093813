// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .overlay-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
} */

@keyframes slideUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes blindFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.canvas {
  opacity: 0;
  transition: opacity 1s, filter 0.5s;
  position: absolute;
}

.canvas.visible {
  animation: slideUp 1s forwards;
}

.canvas.visible-slide-up {
  animation: slideUp 1s forwards;
}

.canvas.visible-blind-fade {
  animation: blindFadeIn 1s forwards;
}

.canvas.grayscale {
  filter: grayscale(100%);
  opacity: 0.3;
}

.canvas {
  transition: transform 0.3s ease;
}

.hover-popup {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 90;
}
`, "",{"version":3,"sources":["webpack://./src/OverlayImages.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;EACE;IACE,0BAA0B;IAC1B,UAAU;EACZ;;EAEA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF;;;AAGA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,UAAU;EACV,mCAAmC;EACnC,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb","sourcesContent":["/* .overlay-container {\n  position: relative;\n  width: 100%;\n  height: 80vh;\n  overflow: hidden;\n} */\n\n@keyframes slideUp {\n  from {\n    transform: translateY(20%);\n    opacity: 0;\n  }\n\n  to {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n\n\n@keyframes blindFadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.canvas {\n  opacity: 0;\n  transition: opacity 1s, filter 0.5s;\n  position: absolute;\n}\n\n.canvas.visible {\n  animation: slideUp 1s forwards;\n}\n\n.canvas.visible-slide-up {\n  animation: slideUp 1s forwards;\n}\n\n.canvas.visible-blind-fade {\n  animation: blindFadeIn 1s forwards;\n}\n\n.canvas.grayscale {\n  filter: grayscale(100%);\n  opacity: 0.3;\n}\n\n.canvas {\n  transition: transform 0.3s ease;\n}\n\n.hover-popup {\n  background-color: rgba(0, 0, 0, 0.7);\n  color: white;\n  padding: 5px 8px;\n  border-radius: 5px;\n  font-size: 14px;\n  white-space: nowrap;\n  z-index: 90;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
