import React, { useEffect, useRef, useState } from 'react';
import './OverlayImages.css';
import Popup from './Popup';
import Papa from 'papaparse';

const imageFiles = [
  '/image1.png',
  '/image2.png',
  '/image3.png',
  '/image4.png',
  '/image5.png',
  '/image6.png',
  '/image7.png',
  '/image8.png',
];

const OverlayImages = () => {
  const canvasRefs = useRef(imageFiles.map(() => React.createRef()));
  const [popup, setPopup] = useState({ show: false, x: 0, y: 0, description: null, transform: '' });
  const [hoverPopup, setHoverPopup] = useState({ show: false, x: 0, y: 0 });
  const [descriptions, setDescriptions] = useState([]);
  const [visibleImages, setVisibleImages] = useState([]);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [scales, setScales] = useState(Array(imageFiles.length).fill(1));
  const imageDataListRef = useRef([]);
  const imagesRef = useRef([]);
  const hoveredIndexRef = useRef(null);
  const animationRefs = useRef([]);
  const prevMousePos = useRef({ x: null, y: null });
  const animationStateRefs = useRef(Array(imageFiles.length).fill('idle'));

  const drawAllImages = () => {
    const container = document.querySelector('.overlay-container');
    const width = container.clientWidth;
    const height = container.clientHeight;
    const pixelRatio = window.devicePixelRatio || 1;

    canvasRefs.current.forEach((canvasRef, index) => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const image = imagesRef.current[index];
      const scale = scales[index];

      if (image) {
        const aspectRatio = image.width / image.height;
        let newWidth, newHeight;

        if (width / height > aspectRatio) {
          newHeight = height * pixelRatio;
          newWidth = newHeight * aspectRatio;
        } else {
          newWidth = width * pixelRatio;
          newHeight = newWidth / aspectRatio;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        context.clearRect(0, 0, canvas.width, canvas.height);

        const imageData = imageDataListRef.current[index];
        const { centerX, centerY } = imageData;

        const scaleX = newWidth / image.width;
        const scaleY = newHeight / image.height;
        const scaledCenterX = centerX * scaleX;
        const scaledCenterY = centerY * scaleY;

        context.save();

        // Translate context to center the non-transparent area
        context.translate(scaledCenterX, scaledCenterY);

        // Apply scaling
        context.scale(scale, scale);

        // Translate back
        context.translate(-scaledCenterX, -scaledCenterY);

        // Draw the image
        context.drawImage(image, 0, 0, newWidth, newHeight);

        context.restore();

        canvas.style.width = `${newWidth / pixelRatio}px`;
        canvas.style.height = `${newHeight / pixelRatio}px`;
        canvas.style.left = `${(width - newWidth / pixelRatio) / 2}px`;
      }
    });
  };

  useEffect(() => {
    // Load descriptions
    Papa.parse(process.env.PUBLIC_URL + '/descriptions.csv', {
      download: true,
      header: true,
      complete: (result) => {
        setDescriptions(result.data);
      },
    });

    // Load the first image immediately and make it visible
    loadImage(0);

    // Load the rest of the images with a delay
    for (let index = 1; index < imageFiles.length; index++) {
      setTimeout(() => {
        loadImage(index);
      }, (index+1) * 1000); // Adjust the delay as needed (e.g., 500ms per image)
    }

    window.addEventListener('resize', drawAllImages);

    return () => {
      window.removeEventListener('resize', drawAllImages);
      document.body.style.cursor = 'default'; // Reset cursor on unmount
    };
  }, []);

  useEffect(() => {
    drawAllImages();
  }, [scales]);

  const loadImage = (index) => {
    const image = new Image();
    image.src = process.env.PUBLIC_URL + imageFiles[index];

    image.onload = () => {
      imagesRef.current[index] = image;

      // Calculate non-transparent area bounding box
      const offCanvas = document.createElement('canvas');
      offCanvas.width = image.width;
      offCanvas.height = image.height;
      const offCtx = offCanvas.getContext('2d');
      offCtx.drawImage(image, 0, 0);

      const imageData = offCtx.getImageData(0, 0, image.width, image.height);
      const data = imageData.data;

      let minX = image.width;
      let minY = image.height;
      let maxX = 0;
      let maxY = 0;

      for (let y = 0; y < image.height; y++) {
        for (let x = 0; x < image.width; x++) {
          const idx = (y * image.width + x) * 4;
          const alpha = data[idx + 3];
          if (alpha !== 0) {
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
          }
        }
      }

      const centerX = (minX + maxX) / 2;
      const centerY = (minY + maxY) / 2;

      // Store the center coordinates
      if (!imageDataListRef.current[index]) imageDataListRef.current[index] = {};
      imageDataListRef.current[index] = {
        centerX,
        centerY,
        minX,
        minY,
        maxX,
        maxY,
      };

      drawAllImages();

      // Make the image visible
      setVisibleImages((prevVisibleImages) => [...prevVisibleImages, index]);
    };
  };

  const isNonTransparentPixel = (canvas, x, y) => {
    const context = canvas.getContext('2d');
    const data = context.getImageData(Math.floor(x), Math.floor(y), 1, 1).data;
    return data[3] !== 0;
  };

  const animateScale = (index, targetScale) => {
    if (animationRefs.current[index]) {
      cancelAnimationFrame(animationRefs.current[index]);
    }

    const duration = 150;
    const initialScale = scales[index];
    const isZoomingIn = targetScale > initialScale;

    // Update animation state
    animationStateRefs.current[index] = isZoomingIn ? 'zoomingIn' : 'zoomingOut';

    let startTime = null;

    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const newScale = initialScale + (targetScale - initialScale) * progress;

      setScales((prevScales) => {
        const newScales = [...prevScales];
        newScales[index] = newScale;
        return newScales;
      });

      if (progress < 1) {
        animationRefs.current[index] = requestAnimationFrame(animate);
      } else {
        // Animation completed, clear the reference
        animationRefs.current[index] = null;
        animationStateRefs.current[index] = 'idle'; // Animation finished
      }
    };

    animationRefs.current[index] = requestAnimationFrame(animate);
  };

  const handleMouseMove = (ev) => {
    const canvas = canvasRefs.current[0].current;
    const rect = canvas.getBoundingClientRect();
    const cx = ((ev.clientX - rect.left) * canvas.width) / rect.width;
    const cy = ((ev.clientY - rect.top) * canvas.height) / rect.height;
  
    const { x: prevX, y: prevY } = prevMousePos.current;
  
    if (prevX === null || prevY === null) {
      prevMousePos.current = { x: cx, y: cy };
      return;
    }
  
    const distanceMoved = Math.sqrt((cx - prevX) ** 2 + (cy - prevY) ** 2);
  
    if (distanceMoved > 5) {
      prevMousePos.current = { x: cx, y: cy };
  
      let foundImage = false;
      for (let i of visibleImages) {
        const canvas = canvasRefs.current[i].current;
        const scale = scales[i];
  
        // Get image data
        const imageData = imageDataListRef.current[i];
        if (!imageData) continue; // Skip if imageData is not yet available
        const { centerX, centerY } = imageData;
  
        const scaleX = canvas.width / imagesRef.current[i].width;
        const scaleY = canvas.height / imagesRef.current[i].height;
        const scaledCenterX = centerX * scaleX;
        const scaledCenterY = centerY * scaleY;
  
        // Adjust mouse coordinates
        const mx = (cx - scaledCenterX) / scale + scaledCenterX;
        const my = (cy - scaledCenterY) / scale + scaledCenterY;
  
        if (isNonTransparentPixel(canvas, mx, my)) {
          foundImage = true;
  
          if (hoveredIndexRef.current === i) return;
  
          if (hoveredIndexRef.current !== null && hoveredIndexRef.current !== i) {
            const prevIndex = hoveredIndexRef.current;
            hoveredIndexRef.current = null;
            animateScale(prevIndex, 1);
          }
  
          hoveredIndexRef.current = i;
          if (!popup.show) {
            animateScale(i, 1.05);
          }
  
          // Show hover popup and change cursor
          const hoverMessage = i >= 6 ? 'Click to learn more' : 'Click to learn the technology';
          setHoverPopup({ show: true, x: ev.clientX, y: ev.clientY, message: hoverMessage });
          document.body.style.cursor = 'pointer';
          return;
        }
      }
  
      if (!foundImage) {
        if (hoveredIndexRef.current !== null) {
          const prevIndex = hoveredIndexRef.current;
          hoveredIndexRef.current = null;
          animateScale(prevIndex, 1);
        }
  
        // Hide hover popup and reset cursor
        setHoverPopup({ show: false, x: 0, y: 0 });
        document.body.style.cursor = 'default';
      }
    }
  };
  

  const handleMouseDown = (ev) => {
    const canvas = canvasRefs.current[0].current;
    const rect = canvas.getBoundingClientRect();
    const cx = ((ev.clientX - rect.left) * canvas.width) / rect.width;
    const cy = ((ev.clientY - rect.top) * canvas.height) / rect.height;

    let clickedOnImage = false;

    for (let i of visibleImages) {
      const canvas = canvasRefs.current[i].current;
      const scale = scales[i];

      // Get image data
      const imageData = imageDataListRef.current[i];
      if (!imageData) continue; // Skip if imageData is not yet available
      const { centerX, centerY } = imageData;

      const scaleX = canvas.width / imagesRef.current[i].width;
      const scaleY = canvas.height / imagesRef.current[i].height;
      const scaledCenterX = centerX * scaleX;
      const scaledCenterY = centerY * scaleY;

      // Adjust mouse coordinates
      const mx = (cx - scaledCenterX) / scale + scaledCenterX;
      const my = (cy - scaledCenterY) / scale + scaledCenterY;

      const isZoomed = scales[i] > 1;
      const animationState = animationStateRefs.current[i];

      if (isZoomed || animationState === 'zoomingOut') {
        // If the image is zoomed or shrinking, consider any click within its canvas
        const canvasRect = canvas.getBoundingClientRect();
        if (
          ev.clientX >= canvasRect.left &&
          ev.clientX <= canvasRect.right &&
          ev.clientY >= canvasRect.top &&
          ev.clientY <= canvasRect.bottom
        ) {
          if (clickedImageIndex === i) return;

          let transform = '';
          if (cx > canvas.width / 2 && cy > canvas.height / 2) {
            transform = 'translate(-100%, -100%)';
          } else if (cx <= canvas.width / 2 && cy > canvas.height / 2) {
            transform = 'translate(0, -100%)';
          } else if (cx > canvas.width / 2 && cy <= canvas.height / 2) {
            transform = 'translate(-100%, 0)';
          }

          setClickedImageIndex(i);
          setPopup({ show: true, x: ev.clientX, y: ev.clientY, description: descriptions[i], transform });

          animateScale(i, 1.05);
          clickedOnImage = true;
          return;
        }
      } else if (isNonTransparentPixel(canvas, mx, my)) {
        if (clickedImageIndex === i) return;

        let transform = '';
        if (cx > canvas.width / 2 && cy > canvas.height / 2) {
          transform = 'translate(-100%, -100%)';
        } else if (cx <= canvas.width / 2 && cy > canvas.height / 2) {
          transform = 'translate(0, -100%)';
        } else if (cx > canvas.width / 2 && cy <= canvas.height / 2) {
          transform = 'translate(-100%, 0)';
        }

        setClickedImageIndex(i);
        setPopup({ show: true, x: ev.clientX, y: ev.clientY, description: descriptions[i], transform });

        animateScale(i, 1.05);
        clickedOnImage = true;
        return;
      }
    }

    if (!clickedOnImage) {
      // If the click was not on any image
      setPopup({ show: false, x: 0, y: 0, description: null, transform: '' });
      setClickedImageIndex(null);
      setScales(Array(imageFiles.length).fill(1));
    }
  };

  const handleClosePopup = () => {
    setPopup({ show: false, x: 0, y: 0, description: null, transform: '' });
    setClickedImageIndex(null);
    setScales(Array(imageFiles.length).fill(1));
  };

  return (
    <div
      className="overlay-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      style={{ position: 'relative', width: '100%', height: '70vh' }}
    >
      {imageFiles.map((file, index) => (
        <canvas
          key={index}
          ref={canvasRefs.current[index]}
          className={`canvas ${
            visibleImages.includes(index)
              ? index < 6
                ? 'visible-slide-up'
                : 'visible-blind-fade'
              : ''
          } ${clickedImageIndex !== null && clickedImageIndex !== index ? 'grayscale' : ''}`}
          style={{
            zIndex: index >= 6 ? 0 : visibleImages.includes(index) ? index + 1 : 0,
            position: 'absolute',
          }}
        ></canvas>
      ))}
      {hoverPopup.show && !popup.show && (
        <div
          className="hover-popup"
          style={{
            position: 'fixed',
            left: hoverPopup.x + 10,
            top: hoverPopup.y + 10,
            pointerEvents: 'none',
          }}
        >
          {hoverPopup.message}
        </div>
      )}
      {popup.show && (
        <Popup
          x={popup.x}
          y={popup.y}
          description={popup.description}
          transform={popup.transform}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default OverlayImages;
